.swiper-button-next {
    right: -32px !important;
}

.swiper-button-prev {
    left: -32px !important;
}

.swiper-button-next, .swiper-button-prev {
    top: 0;
    margin-top: 0;
    width: 100px;
    height: 100%;
}

.swiper-button-next::after, .swiper-button-prev::after {
    color: white;
    font-size: 26px;
}

.swiper-button-next {
    right: -64px;
    left: auto;
}

.swiper-button-prev {
    right: auto;
    left: -64px;
}

.swiper-pagination-bullet-active {
    background: #fff;
}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #28282a;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    height: 100%;
    object-fit: cover;
}
